import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入flexible
import "amfe-flexible"
import "./styles/public.css"
import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast } from 'vant';
import VueCanvasPoster from 'vue-canvas-poster'

// 注册组件库
Vue.use(VueCanvasPoster)
Vue.use(Vant);
Vue.use(Toast);
Vue.config.productionTip = false

if (!sessionStorage.getItem("t")) {
    let timestamp = (new Date()).valueOf()
    sessionStorage.setItem("t",timestamp)
    location.href = `/#${window.location.hash.substring(1)}?t=${timestamp}`;
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
